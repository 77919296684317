import { template as template_2073c36a4135475d92476195b1b60fd1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_2073c36a4135475d92476195b1b60fd1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
