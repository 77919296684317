import { template as template_0c432f45e2a1470a9d2711ef70f21d21 } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_0c432f45e2a1470a9d2711ef70f21d21(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
